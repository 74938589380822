<template>
    <div class="font-inter categories pb-10 mb-10 lg:py-6 enter-content-animation px-4 md:px-0" style="min-height: 40rem">
        <h1 v-if="restaurant?.id" class="font-bold text-2xl sm:text-3xl pb-2">{{restaurant?.restaurant_config?.menu_title_products ?? 'Menu'}}</h1>
        <span v-else class="font-bold my-2 bg-slate-200 w-full"><span class="opacity-0">skeleton skel</span></span>

        <div>
            <div class="grid grid-cols-12 pt-5 gap-4 animate-pulse categories__skeletons" v-if="!categories?.length && !restaurant?.id">
                <div v-for="(i, index) in [1,2,3,4,5,6,7,8]" :key="index" class="relative col-span-6 md:col-span-4 lg:col-span-3 bg-slate-200 rounded-xl h-40 w-full sm:h-60 categories__skeletons--skeleton">
                    <!-- <span class="hidden">placeholder</span>s -->
                </div>
            </div>
            <div v-if="categories?.length" class="grid grid-cols-12 pt-5 gap-4 categories__list">
                <RouterLink v-for="(category, index) in categories" :to="{name:'categories.show', params: { id: category.id }}" :key="index" class="relative col-span-6 md:col-span-4 lg:col-span-3">
                    <div class="relative">
                        <img class="rounded-xl w-full sm:h-60 object-cover opacity-90" 
                             :src="category.banner ? `${apiBaseUrl}/storage/${category.banner}` : '/assets/no-image.png'" 
                             @error="($event.target as HTMLImageElement).src = '/assets/no-image.png'">
                        <div class="absolute inset-0 bg-black opacity-10 rounded-xl"></div>
                    </div>
                    <div class="absolute top-3 left-3 text-white">
                        <h2 class="text-md font-medium">{{ category.name }}</h2>
                    </div>
                </RouterLink>
            </div>
            <div class="flex flex-col justify-center items-center sm:pt-14 sm:mt-14 m-auto" v-if="!categories?.length && restaurant?.id" style="padding-bottom:13.95rem">
                <svg xmlns="http://www.w3.org/2000/svg" width="120" height="70" viewBox="0 0 22 22" fill="none">
                    <path d="M4 8.78516H6C8 8.78516 9 7.78516 9 5.78516V3.78516C9 1.78516 8 0.785156 6 0.785156H4C2 0.785156 1 1.78516 1 3.78516V5.78516C1 7.78516 2 8.78516 4 8.78516ZM16 8.78516H18C20 8.78516 21 7.78516 21 5.78516V3.78516C21 1.78516 20 0.785156 18 0.785156H16C14 0.785156 13 1.78516 13 3.78516V5.78516C13 7.78516 14 8.78516 16 8.78516ZM16 20.7852H18C20 20.7852 21 19.7852 21 17.7852V15.7852C21 13.7852 20 12.7852 18 12.7852H16C14 12.7852 13 13.7852 13 15.7852V17.7852C13 19.7852 14 20.7852 16 20.7852ZM4 20.7852H6C8 20.7852 9 19.7852 9 17.7852V15.7852C9 13.7852 8 12.7852 6 12.7852H4C2 12.7852 1 13.7852 1 15.7852V17.7852C1 19.7852 2 20.7852 4 20.7852Z" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" fill="#D5D5D5"></path>
                </svg>
                <h5 class="font-medium text-lg pt-2">Nenhuma Categoria disponível</h5>
                <p class="text-gray-500 text-center">Volte mais tarde</p>
            </div>
            <!-- <router-link :to="{ name:'show-category', params: { id: 1} }" v-for="(i, index) in [1,2,3]"  :key="index" class="relative col-span-6 sm:col-span-4">
                <img class="rounded-xl h-38 w-full sm:h-60" src="https://mandacaru.bardy.com.br/dashboard/assets/images/uploads/business/41/categories/e4a9124c76b16ae2c79361fe6647fc75.png">
                <div class="absolute top-3 left-3 text-white">
                    <h2 class="text-md font-medium">Bebidas</h2>
                </div>
            </router-link>
            <router-link :to="{ name:'show-category', params: { id: 1} }" v-for="(i, index) in [1,2,3]"  :key="index" class="relative col-span-6 sm:col-span-4">
                <img class="rounded-xl h-38 w-full sm:h-60" src="https://mandacaru.bardy.com.br/dashboard/assets/images/uploads/business/41/categories/654946d6bc81ea8d53622b26ebdcafd0.png">
                <div class="absolute top-3 left-3 text-white">
                    <h2 class="text-md font-medium">Bebidas</h2>
                </div>
            </router-link> -->
        </div>
    </div>
</template>

<script lang="ts" setup>
    import axiosClient from '@/api/axios';
    import {onMounted} from 'vue'
    import { categories, apiBaseUrl, restaurant } from '@/global/state/GlobalState';

    onMounted(() => {
        if(!categories.value){
            axiosClient.get('/v1/categories')
            .then((res) => {
                categories.value = res.data
            })
        } 
    })
</script>

<style lang="scss" scoped>

.categories{
    &__list{
        img{
            height:6rem
        }

    }
    &__skeletons--skeleton{
        min-height:6rem
    }
}

@media screen and (min-width:900px) {
    .categories{
        /*padding: 1.5rem 0;*/
        &__list{
            img{
                height:10rem !important
            }
    
        }
        &__skeletons--skeleton{
            height:10rem !important
        }
    }
}

@media screen and (min-width:600px) {
    .categories{
        padding-top:1.5rem;
        &__list{
            img{
                height:8rem 
            }
    
        }
        &__skeletons--skeleton{
            min-height:8rem
        }
    }
}   

</style>