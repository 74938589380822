<template>
    <MyModal :id="'auth-modal'" :width="'35rem'" :height="'40.5rem'" :isOpen="isAuthModalOpen" :toggleModal="(value: boolean) => isAuthModalOpen = value" :zIndex="130">
        <div class="p-4 font-inter">
            <div class="pb-2">
                <button class="transition duration-200 hover:text-primary" @click="isAuthModalOpen = false">
                  <svg viewBox="0 0 24 24" role="presentation" style="width: 1.5rem; height: 1.5rem;"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" style="fill: currentcolor;"></path></svg>
                </button>
            </div>
            <!-- <div class="flex items-center gap-4 rounded-xl mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" style="min-width:24px" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 8V12" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 16H12.01" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p class="text-sm">Informe seu cpf para poder monitorar o status e o histórico dos seus pedidos.</p>
            </div> -->
            <div class="text-center pb-4">
                <img class="mx-auto pb-4" width="80" :src="restaurant?.restaurant_design_custom?.pwa_logo ? `${apiBaseUrl}/storage/${restaurant.restaurant_design_custom.pwa_logo}`:'/assets/favicon.png'" alt="" srcset="">
                <h4 class="text-2xl font-semibold">Login e cadastro</h4>
                <p class="text-d5 text-sm">Entre na sua conta/crie uma conta</p> 
            </div>
            <form @submit.prevent="login" action="" class="flex flex-col gap-4">
                <div class="flex flex-col gap-2">
                    <label class="text-md font-medium" for="name">Nome <span class="text-red-500">*</span></label>
                    <div class="relative">
                        <svg class="absolute top-1/2 -translate-y-1/2 left-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path opacity="0.4" d="M12 22.01C17.5228 22.01 22 17.5329 22 12.01C22 6.48716 17.5228 2.01001 12 2.01001C6.47715 2.01001 2 6.48716 2 12.01C2 17.5329 6.47715 22.01 12 22.01Z" fill="#171717"/>
                            <path d="M12 6.93994C9.93 6.93994 8.25 8.61994 8.25 10.6899C8.25 12.7199 9.84 14.3699 11.95 14.4299C11.98 14.4299 12.02 14.4299 12.04 14.4299C12.06 14.4299 12.09 14.4299 12.11 14.4299C12.12 14.4299 12.13 14.4299 12.13 14.4299C14.15 14.3599 15.74 12.7199 15.75 10.6899C15.75 8.61994 14.07 6.93994 12 6.93994Z" fill="#171717"/>
                            <path d="M18.78 19.36C17 21 14.62 22.01 12 22.01C9.37997 22.01 6.99997 21 5.21997 19.36C5.45997 18.45 6.10997 17.62 7.05997 16.98C9.78997 15.16 14.23 15.16 16.94 16.98C17.9 17.62 18.54 18.45 18.78 19.36Z" fill="#171717"/>
                        </svg>
                        <input type="text" class="border p-4 py-3 w-full rounded-xl input-primary text-sm md:text-md ps-12" placeholder="ex: João Gomes" v-model="loginForm.name" id="name">
                    </div>
                </div>
                <div class="flex flex-col gap-2">
                    <label class="text-md font-medium" for="email">Email <span class="text-red-500">*</span></label>
                    <div class="relative">
                        <svg class="absolute top-1/2 -translate-y-1/2 left-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path opacity="0.4" d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" fill="#171717"/>
                            <path d="M11.9998 12.87C11.1598 12.87 10.3098 12.61 9.65978 12.08L6.52978 9.57997C6.20978 9.31997 6.14978 8.84997 6.40978 8.52997C6.66978 8.20997 7.13978 8.14997 7.45978 8.40997L10.5898 10.91C11.3498 11.52 12.6398 11.52 13.3998 10.91L16.5298 8.40997C16.8498 8.14997 17.3298 8.19997 17.5798 8.52997C17.8398 8.84997 17.7898 9.32997 17.4598 9.57997L14.3298 12.08C13.6898 12.61 12.8398 12.87 11.9998 12.87Z" fill="#171717"/>
                        </svg>
                        <input type="email" class="p-4 py-3 w-full rounded-xl input-primary text-sm md:text-md ps-12" placeholder="ex: seuemail@gmail.com" v-model="loginForm.email" id="email">
                    </div>
                </div>
                <div class="flex flex-col gap-2">
                    <label class="text-md font-medium" for="cpf">CPF <span class="text-red-500">*</span></label>
                    <div class="relative">
                        <svg class="absolute top-1/2 -translate-y-1/2 left-4" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path opacity="0.4" d="M18.0156 3H6.01562C3.80562 3 2.01562 4.78 2.01562 6.97V17.03C2.01562 19.22 3.80562 21 6.01562 21H18.0156C20.2256 21 22.0156 19.22 22.0156 17.03V6.97C22.0156 4.78 20.2256 3 18.0156 3Z" fill="#171717"/>
                            <path d="M19.0156 8.75H14.0156C13.6056 8.75 13.2656 8.41 13.2656 8C13.2656 7.59 13.6056 7.25 14.0156 7.25H19.0156C19.4256 7.25 19.7656 7.59 19.7656 8C19.7656 8.41 19.4256 8.75 19.0156 8.75Z" fill="#171717"/>
                            <path d="M19.0156 12.75H15.0156C14.6056 12.75 14.2656 12.41 14.2656 12C14.2656 11.59 14.6056 11.25 15.0156 11.25H19.0156C19.4256 11.25 19.7656 11.59 19.7656 12C19.7656 12.41 19.4256 12.75 19.0156 12.75Z" fill="#171717"/>
                            <path d="M19.0156 16.75H17.0156C16.6056 16.75 16.2656 16.41 16.2656 16C16.2656 15.59 16.6056 15.25 17.0156 15.25H19.0156C19.4256 15.25 19.7656 15.59 19.7656 16C19.7656 16.41 19.4256 16.75 19.0156 16.75Z" fill="#171717"/>
                            <path d="M8.51557 11.79C9.79134 11.79 10.8256 10.7558 10.8256 9.48004C10.8256 8.20427 9.79134 7.17004 8.51557 7.17004C7.23979 7.17004 6.20557 8.20427 6.20557 9.48004C6.20557 10.7558 7.23979 11.79 8.51557 11.79Z" fill="#171717"/>
                            <path d="M9.31565 13.11C8.78565 13.06 8.23565 13.06 7.70565 13.11C6.02565 13.27 4.67565 14.6 4.51565 16.28C4.50565 16.42 4.54565 16.56 4.64565 16.66C4.74565 16.76 4.87565 16.83 5.01565 16.83H12.0157C12.1557 16.83 12.2957 16.77 12.3857 16.67C12.4757 16.57 12.5257 16.43 12.5157 16.29C12.3457 14.6 11.0057 13.27 9.31565 13.11Z" fill="#171717"/>
                        </svg>
                        <input type="tel" class="p-4 py-3 w-full rounded-xl input-primary text-sm md:text-md ps-12" placeholder="ex: 000.000.000-00" @input="setCpfMask" v-model="loginForm.cpf" id="cpf">
                    </div>
                </div>
                <div class="modal-button-wrapper">
                    <button class="w-full p-4 bg-red-300 text-white rounded-xl font-medium sm:mt-14 transition duration-200"
                    :style="{background: loginForm.name && loginForm.email && loginForm.cpf.length == 14 ? restaurant.restaurant_design_custom.primary_color ? restaurant.restaurant_design_custom.primary_color : '#323232' : '#f5f5f5', color: loginForm.name && loginForm.email && loginForm.cpf.length == 14 ? '#fff' : '#949494'}">Salvar</button>
                </div>
            </form>
        </div>
    </MyModal>
</template>
<script lang="ts" setup>
import { CustomerModel } from '@/models/CustomerModel';
import { customer, isAuthModalOpen } from '@/global/state/AuthState'
import { restaurant, apiBaseUrl } from '@/global/state/GlobalState';
import MyModal from '@/components/modal/MyModal.vue'
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import useMask from '@/utils/useMask'
import axiosClient from '@/api/axios';


const loginForm = ref<CustomerModel>({
    name: '',
    cpf: '',
    email:''
})
const toast = useToast()
const { cpfMask } = useMask()

function login(): void{
    if(loginForm.value.name && loginForm.value.email && loginForm.value.cpf.length == 14){
        localStorage.setItem('customer', JSON.stringify(loginForm.value))
        customer.value = loginForm.value
        isAuthModalOpen.value = false
        toast.success('Salvo com sucesso!', {timeout:1500})
        axiosClient.get(`/v1/customer/cpf/${customer.value.cpf}`)
        .then((res:any) => {
            localStorage.setItem('customer', JSON.stringify({...customer.value, is_registered: true}))
            customer.value = res.data.customer
            customer.value.is_registered = true;
        }).catch(() => {
            localStorage.setItem('customer', JSON.stringify({...customer.value, is_registered: false}))
            customer.value.is_registered = false;
        })
    }
}

function setCpfMask(){
    loginForm.value.cpf = cpfMask(loginForm.value.cpf)
}

</script>

<style lang="scss" scoped>
    input{
        border:1px solid #d5d5d5
    }
</style>
