<template>
    <div v-if="!selectedCategory" class="home web-page font-roboto enter-content-animation">
      <div v-if="categories?.length" class="home__categories-list font-inter sm:pb-8 sm:pt-6">
        <swiper
          :slides-per-view="9"
          :space-between="50"
          :pagination="true"
          :modules="modules"
          :breakpoints="{ 0: { slidesPerView: 4, spaceBetween: 20}, 450:{ slidesPerView:5, spaceBetween: 20 }, 900: {slidesPerView:6, spaceBetween:40 }, 1200:{ slidesPerView:9, spaceBetween:40 } }"
          :free-mode="true"
          class="pb-10"
        >
          <swiper-slide v-for="category in categories" :key="category.id">
            <RouterLink :to="{name:'categories.show', params: { id: category.id }}">
              <div class="transition-transform transform-gpu hover:scale-110 cursor-pointer home__categories-list__category items-center">
                <img class="rounded-2xl" :src="apiBaseUrl + '/storage/' + category.image" alt="" srcset="" @error="($event.target as HTMLImageElement).src = '/assets/no-image.png'">
                <p class="text-center text-sm font-inter only-1-lines">{{ category.name }}</p>
              </div>
            </RouterLink>
          </swiper-slide>
        </swiper>
      </div>
      <div v-if="!categories?.length && !restaurant?.id" class="home__categories-list font-inter sm:pb-8 sm:pt-6">
        <swiper
          :slides-per-view="9"
          :space-between="50"
          :pagination="true"
          :modules="modules"
          :breakpoints="{ 0: { slidesPerView: 4, spaceBetween: 20}, 450:{ slidesPerView:5, spaceBetween: 20 }, 900: {slidesPerView:6, spaceBetween:40 }, 1200:{ slidesPerView:9, spaceBetween:40 } }"
          :free-mode="true"
          class="pb-10"
        >
          <swiper-slide v-for="i in [1,2,3,4,5,6,7,8,9]" :key="i">
            <button>
              <div class="transition-transform transform-gpu hover:scale-110 cursor-pointer hover:text-green-500 home__categories-list__category skeleton animate-pulse">
                <div class="img-skeleton rounded-2xl bg-slate-200 w-full"></div>
                <p class="text-center mx-auto p-skeleton bg-slate-200 text-slate-200 text-sm h-4 w-full"></p>
              </div>
            </button>
          </swiper-slide>
        </swiper>
      </div>
      
      <div class="home__banners pl-4 sm:pl-0 sm:pb-8" v-if="restaurant.banners?.length">
        <swiper
          :slides-per-view="3"
          :space-between="50"
          :pagination="true"
          :modules="modules"
          :breakpoints="{ 0: { slidesPerView: 1.13, spaceBetween: 0}, 430: { slidesPerView:1.3, spaceBetween: 10 }, 500:{ slidesPerView:1.3, spaceBetween: 20 }, 680: { slidesPerView:1.7, spaceBetween: 20 }, 830:{ slidesPerView:2, spaceBetween: 20 }, 1050: { slidesPerView: 2.5, spaceBetween:20  }, 1200: { slidesPerView: 3, spaceBetween:20 } }"
          :free-mode="true"
          class="pb-10"
        >
          <swiper-slide class="pr-4 sm:p-0" v-for="(banner) in restaurant.banners" :key="banner.id">
            <div class="home__banners__wrapper w-full rounded-xl">
              <img style="perspective: 1px;" class="w-full img-skeleton rounded-2xl object-fill" :src="apiBaseUrl + '/storage/' + banner.image" alt="">
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <div v-if="!restaurant.id" class="home__banners animate-pulse pl-4 sm:pl-0 sm:pb-8">
        <swiper
          :slides-per-view="3"
          :space-between="50"
          :pagination="true"
          :modules="modules"
          :breakpoints="{ 0: { slidesPerView: 1.13, spaceBetween: 0}, 430: { slidesPerView:1.3, spaceBetween: 10 }, 500:{ slidesPerView:1.3, spaceBetween: 20 }, 680: { slidesPerView:1.7, spaceBetween: 20 }, 830:{ slidesPerView:2, spaceBetween: 20 }, 1050: { slidesPerView: 2.5, spaceBetween:20  }, 1200: { slidesPerView: 3, spaceBetween:20 } }"
          :free-mode="true"
          class="pb-10"
        >
          <swiper-slide class="pr-4 sm:p-0" v-for="i in [1,2,3]" :key="i">
            <div class="bg-slate-200 img-skeleton rounded-2xl"></div>
          </swiper-slide>
        </swiper>
      </div>

      <div class="sm:pb-5 font-inter home__info pl-4 sm:pl-0">
        <swiper
            :slides-per-view="4.4"
            :space-between="20"
            :pagination="true"
            :modules="modules"
            :breakpoints="{ 0: { slidesPerView: 1.4, spaceBetween: 10}, 450:{ slidesPerView: 1.6, spaceBetween: 10},  600: { slidesPerView:1.8 }, 900:{ slidesPerView:3, spaceBetween: 10 }, 1100:{ slidesPerView:4, spaceBetween: 20 } }"
            class="pb-10"
          >
            <swiper-slide>
              <div class="border p-4 rounded-xl flex items-center gap-4 home__info__card">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M19.24 5.58H18.84L15.46 2.2C15.19 1.93 14.75 1.93 14.47 2.2C14.2 2.47 14.2 2.91 14.47 3.19L16.86 5.58H7.14L9.53 3.19C9.8 2.92 9.8 2.48 9.53 2.2C9.26 1.93 8.82 1.93 8.54 2.2L5.17 5.58H4.77C3.87 5.58 2 5.58 2 8.14C2 9.11 2.2 9.75 2.62 10.17C2.86 10.42 3.15 10.55 3.46 10.62C3.75 10.69 4.06 10.7 4.36 10.7H19.64C19.95 10.7 20.24 10.68 20.52 10.62C21.36 10.42 22 9.82 22 8.14C22 5.58 20.13 5.58 19.24 5.58Z" fill="#171717"/>
                  <path d="M19.0502 12H4.87016C4.25016 12 3.78016 12.55 3.88016 13.16L4.72016 18.3C5.00015 20.02 5.75015 22 9.08015 22H14.6902C18.0602 22 18.6602 20.31 19.0202 18.42L20.0302 13.19C20.1502 12.57 19.6802 12 19.0502 12ZM12.0002 19.5C9.66016 19.5 7.75016 17.59 7.75016 15.25C7.75016 14.84 8.09016 14.5 8.50015 14.5C8.91015 14.5 9.25015 14.84 9.25015 15.25C9.25015 16.77 10.4802 18 12.0002 18C13.5202 18 14.7502 16.77 14.7502 15.25C14.7502 14.84 15.0902 14.5 15.5002 14.5C15.9102 14.5 16.2502 14.84 16.2502 15.25C16.2502 17.59 14.3402 19.5 12.0002 19.5Z" fill="#171717"/>
                </svg>
                <div class="">
                  <p class="font-medium text-sm md:text-md">Pedido mínimo</p>
                  <h6 v-if="restaurant.minimum_order != null" class="font-bold text-xs md:text-sm text-success">{{ restaurant.minimum_order?.toLocaleString('pt-BR', {style:'currency', currency: 'BRL'}) }}</h6>
                  <h6 v-else class="bg-slate-200 w-15 h-4 animate-pulse"></h6>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="border p-4 rounded-xl flex items-center gap-4 home__info__card">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16"> <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/> <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/> </svg>
                <div>
                  <p class="font-medium text-sm md:text-md">{{ daysFormat[new Date().getDay()] }}</p>
                  <!-- <h6 v-if="restaurant.delivery_time" class="font-bold text-xs md:text-sm text-secondary">{{ restaurant.delivery_time }}</h6> -->
                  <h6 class="bg-slate-200 w-15 h-4 animate-pulse" v-if="!restaurant.id"></h6>
                  <h6 v-if="todaySchedule" class="text-xs text-success font-bold md:text-sm pr-10">{{ todaySchedule.opening_time.slice(0, 5) }}h às {{ todaySchedule.closing_time.slice(0, 5) }}h</h6>
                  <p class="text-xs md:text-sm text-red-500 font-medium pr-10" v-if="!todaySchedule && restaurant.id">Fechado</p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide v-if="restaurant.restaurant_config?.whatsapp">
              <div class="border p-4 rounded-xl flex items-center gap-4 home__info__card">
                <svg viewBox="0 0 24 24" role="presentation" style="width: 1.65rem; height: 1.65rem;"><path d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" style="fill: currentcolor;"></path></svg>
                <div>
                  <p class="font-medium text-sm md:text-md">Whatsapp</p>
                  <h6 v-if="restaurant.restaurant_config?.whatsapp" class="font-bold text-xs md:text-sm text-success">{{ restaurant.restaurant_config?.whatsapp }}</h6>
                  <h6 v-else class="bg-slate-200 w-15 h-4 animate-pulse"></h6>
                </div>
              </div>
            </swiper-slide>
        </swiper>
      </div>

      <ProductModal :setSelectedProduct="(value: any) => selectedProduct = value" :setSelectedProductVariations="(value: string) => selectedProduct.variations = value" :isOpen="isProductModalOpen" :toggleModal="toggleProductModal" :selectedProduct="selectedProduct"></ProductModal>

      <div class="flex flex-col home__categories font-inter">
        <div v-for="category in categories" :key="category.id">
          <div class="pb-10 home__categories__item" v-if="category.products.length && business?.base_urls">
            <h5 class="text-xl md:text-2xl text-zinc-800 font-bold pb-6">{{ category.name }}</h5>
            <div class="home__categories__item__products grid grid-cols-12">
              <div v-for="product in category.products" :key="product.id" :class="category.mobile_products_per_row == 2 ? 'col-span-6':'col-span-4'" class="md:col-span-4 lg:col-span-3 font-inter cursor-pointer" @click="setModalProduct(product)">
                <div class="relative w-full">
                  <img class="w-full rounded-2xl" :src="apiBaseUrl + '/storage/' + product.small_image?.path" alt="" @error="($event.target as HTMLImageElement).src = '/assets/no-image.png'">
                  <div v-if="product.discount_type == 'percent' && product.discount" class="absolute top-0 p-2 font-semibold text-white bg-red-300 rounded-br-2xl rounded-tl-2xl">{{ product.discount }}% off</div>
                </div>
                <h5 class="font-bold py-2">{{ product.name }}</h5>
                <p v-if="product.description" class="text-gray-500 max-w-full mb-2 text-sm only-2-lines overflow-hidden">{{ product.description }}</p>
                <!-- <div class="text-xs flex-wrap">
                  <div class="flex items-center gap-2 pb-2">
                    <svg viewBox="0 0 24 24" role="presentation" style="width: 1.2rem; height: 1.2rem;"><path d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z" style="fill: #323232;"></path></svg>
                    Serve n pessoas
                  </div>
                  <div class="flex items-center gap-2">
                    <svg viewBox="0 0 24 24" role="presentation" style="width: 1.2rem; height: 1.2rem;"><path d="M12,3A4,4 0 0,1 16,7C16,7.73 15.81,8.41 15.46,9H18C18.95,9 19.75,9.67 19.95,10.56C21.96,18.57 22,18.78 22,19A2,2 0 0,1 20,21H4A2,2 0 0,1 2,19C2,18.78 2.04,18.57 4.05,10.56C4.25,9.67 5.05,9 6,9H8.54C8.19,8.41 8,7.73 8,7A4,4 0 0,1 12,3M12,5A2,2 0 0,0 10,7A2,2 0 0,0 12,9A2,2 0 0,0 14,7A2,2 0 0,0 12,5Z" style="fill: #323232;"></path></svg>
                    500g
                  </div>
                </div> -->
                <span v-if="!product.discount" class="text-green-400 font-bold text-neutral-700" style="font-size:.9rem">{{ product.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                <p v-if="product.discount" class="font-bold"><span>De: </span><span class="line-through text-red-400">{{ product.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span></p>
                <div v-if="product.discount" class="font-bold flex items-center gap-2">
                  <p>
                    <span class="">Por: </span> <span class="text-green-400">{{ (product.price - (product.discount_type == 'amount' ? product.discount : (product.price/100 * product.discount))).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col justify-center items-center sm:pt-14 m-auto font-inter" style="padding-bottom:7rem" v-if="!categories?.some((c: any) => c.products?.length) && restaurant?.id">
        <svg xmlns="http://www.w3.org/2000/svg" width="252" height="166" viewBox="0 0 252 166" fill="none">
          <path d="M218.84 48.2183V58.5663C238.937 45.3962 235.303 43.5147 250.611 62.543C252.92 65.4507 252.193 69.5984 249.071 71.4798L218.84 90.0377V145.54C218.84 156.401 209.689 165.082 198.828 165.082H53.1446C42.2835 165.082 33.1328 156.401 33.1328 145.54V90.0377L2.90145 71.4798C-0.220032 69.5984 -0.904194 65.3651 1.40485 62.543C16.713 43.9851 12.0949 44.7975 33.1328 58.5663C33.1328 50.5701 31.7218 44.541 37.3661 42.4885C57.164 34.193 77.2185 28.8053 97.5295 26.2824C91.3293 6.1424 124.853 -6.98494 145.592 6.27069C152.989 10.9743 157.009 18.6711 154.358 26.6245C174.284 29.3184 194.381 34.5779 214.607 42.4885C217.044 43.2154 218.84 45.5245 218.84 48.2183ZM110.315 25.0851L141.444 25.2989C149.611 16.7469 125.965 7.93833 112.838 16.3193C108.904 18.7994 107.493 21.9209 110.315 25.0851ZM55.1971 46.5935H194.595C148.842 36.2027 100.95 35.7324 55.1971 46.5935Z" fill="#E0E0E0"/>
        </svg>
        <h5 class="font-medium text-lg pt-2">Nenhum produto disponível</h5>
        <p class="text-gray-500 text-center">Volte mais tarde</p>
      </div>

      <div class="home__categories" v-if="!categories || !business?.base_urls">
        <h5 class="mb-6 h-6 w-30 animate-pulse bg-slate-200"></h5>
        <div class="home__categories__item__products grid grid-cols-12 animate-pulse">
          <div class="col-span-6 md:col-span-4 lg:col-span-3 font-inter cursor-pointer" v-for="item in [1,2,3,4]" :key="item">
            <div class="w-full rounded-xl h-40 bg-slate-200"></div>
            <h5 class="font-bold my-2 bg-slate-200 w-full"><span class="opacity-0">skeleton</span></h5>
            <p class="font-bold bg-slate-200 w-15"><span class="opacity-0">skeleton</span></p>
          </div>
        </div>
      </div>
    </div>
    <CategoriesShow v-else :category="selectedCategory" :setCategory="(value: any) => selectedCategory = value" />
</template>

<script lang="ts">
import { Pagination, FreeMode } from 'swiper/modules';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { computed, onMounted, ref } from 'vue';
import axiosClient from '@/api/axios';
import { business, restaurant, categories, apiBaseUrl } from '@/global/state/GlobalState';
import ProductModal from '@/components/modal/ProductModal.vue';
import CategoriesShow from '../categories/CategoriesShow.vue';

export default {
  name: 'home-page',
  components: {
    Swiper,
    SwiperSlide,
    ProductModal,
    CategoriesShow
},
  setup() {
      const isProductModalOpen = ref<boolean>(false)
      const isCategoriesLoading = ref<boolean>(true)
      const selectedProduct = ref<any>({})
      const selectedCategory = ref<any>(null)
      const daysFormat: any = {
          0: 'Domingo',
          1: 'Segunda-feira',
          2: 'Terça-feira',
          3: 'Quarta-feira',
          4: 'Quinta-feira',
          5: 'Sexta-feira',
          6: 'Sábado'
      };


      const setModalProduct = (product: any) => {
        selectedProduct.value = product
        isProductModalOpen.value = !isProductModalOpen.value
      }

      function toggleProductModal(isOpen: boolean){
        isProductModalOpen.value = isOpen
      }

      onMounted(() => {
        if(!categories.value){
          axiosClient.get('/v1/categories')
          .then((res) => {
              isCategoriesLoading.value = false
              categories.value = res.data
          })
        }
      })

      const todaySchedule = computed(() => restaurant.value.schedules?.find((s: any) => s.day == new Date().getDay()))

      return {
        modules: [Pagination, FreeMode],
        isProductModalOpen,
        setModalProduct,
        toggleProductModal,
        categories,
        business,
        isCategoriesLoading,
        restaurant,
        selectedProduct, 
        selectedCategory,
        apiBaseUrl, 
        todaySchedule,
        daysFormat
      };
    },
};
</script>

<style lang="scss">
  @import '../../../global/scss/app.scss';
  .home{
    .swiper-pagination-bullet.swiper-pagination-bullet-active{
      background-color: v-bind("restaurant.restaurant_design_custom?.secondary_color ?? '#ccc'") !important
    }

    .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal{
    }

    .swiper-slide.swiper-slide-next{
      display:flex;
      justify-content: center;
      align-items: center;
    }

    .swiper.pb-10{
      padding-bottom:2.5rem
    }

    &__banners{
      @media screen and (max-width:600px) {
        img,.img-skeleton {
          min-height:150px !important;
          height:170px !important;
          margin-bottom:0 !important;
          width: 400px !important;
        }
  
        &__wrapper{
          height:170px
        }
      }

      @media screen and (max-width:520px){
        img,.img-skeleton {
          height:150px !important;
        }
  
        &__wrapper{
          height:150px
        }
      }

      img, .img-skeleton{
        height:13.4rem;
        width:26rem;
        max-width:100%;
      }
    }
    &__categories-list__category{
      display: flex;
      gap:.75rem;
      flex-direction: column;
      margin:.0;
      img, .img-skeleton{
        height:4rem;
        width:80px;
        width:80px;
        object-fit: fill;
      }
      p{
        color: v-bind('restaurant.restaurant_design_custom?.menu_text_color')
      }

      &:hover{
        p{
          color: v-bind('restaurant.restaurant_design_custom?.menu_text_hover_color')
        }
      }
    }

    &__categories__item{
      margin-bottom:2rem;
    }

    &__categories__item__products{
      column-gap:4.5rem;
      row-gap:2.5rem;

      img{
        height:180px;
        max-height:180px;
        object-fit: contain;
      }

      h5{
        color:#292f31;
        font-size: 16px;
      }

      p{
        color: #575757;
        font-size: 13px;
      }
    }

    &__info{
      &__card{
        width:100%
      }
    }
  }

  @media screen and (max-width:1024px) {
    .home{
      &__categories-list{
        /*padding:0 1rem*/
      }
      &__categories{
        padding:0 1rem  
      }

      &__categories__item__products{
        gap:1rem;
        img{
          height:180px !important
        }      
      }
    }
  }

  @media screen and (max-width:600px) {
    .home{
      &__categories__item__products{
        gap:1rem;
        img{
          height:180px !important
        }      
      }
    }

    .home__categories-list{
      padding:0 1rem !important;
      button{
        width:100%
      }
    }

    .home__categories-list__category{
      padding:0 !important;
      width:100% !important;

      &.skeleton{
        padding-bottom:0 !important;
      }
      img{
        height: 55px !important;
        width: 65px !important;
      }

      .img-skeleton{
        width:70px
      }

      p{
        font-size:13px
      }
    }
  }

  @media screen and (max-width:540px){
    .home{
      &__categories__item__products{
        .col-span-6{
          img{
            height:130px !important;
          }
        }
        img{
          height:90px !important;
        }      
      }
    }
  }
</style>