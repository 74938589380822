import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// toast
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const optionsToast = { }
createApp(App).use(router).use(Toast, optionsToast).mount('#app')
