
import { Pagination, FreeMode } from 'swiper/modules';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { computed, onMounted, ref } from 'vue';
import axiosClient from '@/api/axios';
import { business, restaurant, categories, apiBaseUrl } from '@/global/state/GlobalState';
import ProductModal from '@/components/modal/ProductModal.vue';
import CategoriesShow from '../categories/CategoriesShow.vue';

const __default__ = {
  name: 'home-page',
  components: {
    Swiper,
    SwiperSlide,
    ProductModal,
    CategoriesShow
},
  setup() {
      const isProductModalOpen = ref<boolean>(false)
      const isCategoriesLoading = ref<boolean>(true)
      const selectedProduct = ref<any>({})
      const selectedCategory = ref<any>(null)
      const daysFormat: any = {
          0: 'Domingo',
          1: 'Segunda-feira',
          2: 'Terça-feira',
          3: 'Quarta-feira',
          4: 'Quinta-feira',
          5: 'Sexta-feira',
          6: 'Sábado'
      };


      const setModalProduct = (product: any) => {
        selectedProduct.value = product
        isProductModalOpen.value = !isProductModalOpen.value
      }

      function toggleProductModal(isOpen: boolean){
        isProductModalOpen.value = isOpen
      }

      onMounted(() => {
        if(!categories.value){
          axiosClient.get('/v1/categories')
          .then((res) => {
              isCategoriesLoading.value = false
              categories.value = res.data
          })
        }
      })

      const todaySchedule = computed(() => restaurant.value.schedules?.find((s: any) => s.day == new Date().getDay()))

      return {
        modules: [Pagination, FreeMode],
        isProductModalOpen,
        setModalProduct,
        toggleProductModal,
        categories,
        business,
        isCategoriesLoading,
        restaurant,
        selectedProduct, 
        selectedCategory,
        apiBaseUrl, 
        todaySchedule,
        daysFormat
      };
    },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "0f343ea8": (_ctx.restaurant.restaurant_design_custom?.secondary_color ?? '#ccc'),
  "5e42d501": (_ctx.restaurant.restaurant_design_custom?.menu_text_color),
  "3510f5be": (_ctx.restaurant.restaurant_design_custom?.menu_text_hover_color)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__