export default function useMask() {
    function cpfMask(value: string): string {
        value = value.replace(/\D/g, "");

        const mask =
            value.length <= 3
                ? value
                : value.length <= 6
                ? `${value.slice(0, 3)}.${value.slice(3)}`
                : value.length <= 9
                ? `${value.slice(0, 3)}.${value.slice(3, 6)}.${value.slice(6)}`
                : `${value.slice(0, 3)}.${value.slice(3, 6)}.${value.slice(
                      6,
                      9
                  )}-${value.slice(9, 11)}`;

        if (value.length > 11) {
            value = value.slice(0, 11); // Limita ao tamanho máximo de um CPF
        }

        return mask;
    }

    function cnpjMask(value: string): string {
        value = value.replace(/\D/g, "");

        const mask =
            value.length <= 2
                ? value
                : value.length <= 5
                ? `${value.slice(0, 2)}.${value.slice(2)}`
                : value.length <= 8
                ? `${value.slice(0, 2)}.${value.slice(2, 5)}.${value.slice(5)}`
                : value.length <= 12
                ? `${value.slice(0, 2)}.${value.slice(2, 5)}.${value.slice(
                      5,
                      8
                  )}/${value.slice(8)}`
                : `${value.slice(0, 2)}.${value.slice(2, 5)}.${value.slice(
                      5,
                      8
                  )}/${value.slice(8, 12)}-${value.slice(12, 14)}`;

        if (value.length > 14) {
            value = value.slice(0, 14); // Limita ao tamanho máximo de um CNPJ
        }

        return mask;
    }

    function cepMask(value: string): string {
        value = value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos

        if (value.length > 5) {
            value = value.slice(0, 5) + "-" + value.slice(5, 8);
        }

        return value;
    }

    function phoneNumberMask(value: string): string {
        value = value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos

        if (value.length >= 2) {
            // Formata os primeiros 2 dígitos
            value = value.substring(0, 2) + " " + value.substring(2);
        }
        if (value.length >= 4) {
            // Formata o nono dígito
            value = value.substring(0, 4) + " " + value.substring(4);
        }
        if (value.length >= 9) {
            // Formata a primeira parte do número
            value = value.substring(0, 9) + "-" + value.substring(9);
        }
        if (value.length > 14) {
            // Limita o número a 14 dígitos
            value = value.substring(0, 14);
        }

        return value;
    }

    function rgMask(value: string): string {
        value = value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos

        if (value.length > 9) {
            value = value.slice(0, 9);
        }

        // Adiciona a máscara para RG (XX.XXX.XXX-X)
        if (value.length > 2) {
            value = value.slice(0, 2) + "." + value.slice(2);
        }
        if (value.length > 6) {
            value = value.slice(0, 6) + "." + value.slice(6);
        }
        if (value.length > 9) {
            value = value.slice(0, 9) + "-" + value.slice(9);
        }

        return value;
    }

    function brlMask(value: any) {
        if (value) {
            value = value.replace(/\D/g, "");
            value = parseFloat(value) / 100;

            // Formata o valor como moeda brasileira (BRL)
            return value.toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }

        return "";
    }

    function integerMask(value: any): number {
        return value.replace(/\D/g, "");
    }

    return {
        cpfMask,
        cnpjMask,
        cepMask,
        phoneNumberMask,
        rgMask,
        brlMask,
        integerMask,
    };
}
