import { createRouter, createWebHistory } from "vue-router";
import Home from './pages/web/home/Home.vue'
import CategoriesIndex from './pages/web/categories/CategoriesIndex.vue'
import WebLayout from './layouts/web/weblayout/WebLayout.vue'
import OrdersIndex from './pages/web/orders/OrdersIndex.vue'
import NotFound from './pages/web/404/NotFound.vue'
import NonexistentDomain from './pages/web/404/NonexistentDomain.vue'
import ProductsIndex from './pages/web/products/ProductsIndex.vue'
import CategoriesShow from "./pages/web/categories/CategoriesShow.vue";
import OrdersShow from "./pages/web/orders/OrdersShow.vue";

const routes = [
    { 
        path:'/', 
        component: WebLayout,
        children: [
            { path: '/', component: Home, name: 'home' },
            { path: '/categories', name: 'categories', component: CategoriesIndex },
            { path: '/categories/:id', name: 'categories.show', component: CategoriesShow, props: true },
            { path: '/products/:search?', name: 'products', component: ProductsIndex, props: true },
            { path: '/orders', name: 'orders', component: OrdersIndex},
            { path: '/orders/:id', name: 'orders.show', component: OrdersShow, props: true},
            { path: '/404', name: '404', component: NotFound},
            { path: '/nonexistent-domain', name: 'nonexistent-domain', component: NonexistentDomain},
        ] 
    },
]
  
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
});

export default router;