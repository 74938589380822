import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { cmFirebaseToken, orders } from '@/global/state/GlobalState';
import { appearNotification, notifications, addNotifications } from '@/global/state/NotificationState';
import { restaurant } from '@/global/state/GlobalState';
import { customer } from '@/global/state/AuthState';
import axiosClient from '@/api/axios';
import { OrderModel } from '@/models/OrderModel';
import router from '@/router';

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp)

onMessage(messaging, (payload: any) => {
    if(payload.data.type == 'order_status' || payload.data.type == 'kitchen_status' || payload.data.type == 'counter_status'){
        const statusTranslations: any = {
            'pending': {label: 'pendente', color:'#918373'},
            'preparing': {label: 'preparando', color:'#FF9B3D'},
            'prepared': {label: 'preparado', color:'#59A6F8'},
            'delivered': {label: 'entregue', color: '#00c9a7'},
            'cancelled': {label: 'cancelado', color:'#F83535'},
            'finished': {label:'finalizado'}
        }

        const statusType = payload.data.type == 'order_status' ? '' : (payload.data.type == 'kitchen_status' ? ' de cozinha' : ' de balcão')
        const notificationData = {
            order_id: payload.data.order_id,
            title: 'Seu pedido foi atualizado',
            message: `O status${statusType} do seu pedido mudou de ${statusTranslations[payload.data.old_status].label} para ${statusTranslations[payload.data.new_status].label}`,
            date: new Date(),
            restaurant_id: restaurant.value.id,
            type:payload.data.type,
            new_status: payload.data.new_status
        }

        notifications.value.push(notificationData)

        addNotifications([notificationData])

        appearNotification('Seu pedido foi atualizado',`O status do seu pedido mudou de ${statusTranslations[payload.data.old_status].label} para ${statusTranslations[payload.data.new_status].label}`)
    } else if(payload.data.type == 'answer_customer_request'){
        const notificationData = {
            title: payload.data.title,
            message: payload.data.body,
            date: new Date(),
            restaurant_id: restaurant.value.id,
            type:payload.data.type,
            answer: payload.data.answer,
            customer_request_type: payload.data.customer_request_type
        }

        notifications.value.push(notificationData)

        addNotifications([notificationData])

        appearNotification(payload.data.title, payload.data.body, payload.data.answer == 'refuse')
    }
});  

getToken(messaging, { vapidKey: 'BMO5D3aM00o8csdPvxIFkMLZa6ad0aOswN3cl-uQVl-NbgZB8dqsWWeKSkGbN4hA9N-NgsrcD6yS1CMNPhjp04g' }).then((currentToken: any) => {
    if (currentToken) {
        // if(currentToken != localStorage.getItem('cmFirebaseToken') && customer.value?.cpf){
            localStorage.setItem('cmFirebaseToken', currentToken)
            cmFirebaseToken.value = currentToken
            if(customer.value.cpf && customer.value.is_registered){
                axiosClient.put('/v1/customer/cm-firebase-token', {
                    cm_firebase_token: currentToken,
                    cpf: customer.value.cpf
                }).then((res: any) => {
                    console.log('cm firebase token updated succesfully')
                })
            }
        // }

    } else {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                console.log('Permissão para notificações concedida.');
            } else {
                console.log('Permissão para notificações negada.');
            }
        });

        console.log('No registration token available. Request permission to generate one.');
        // ...
    }
}).catch((err:any) => {
    console.log('An error occurred while retrieving token. ', err);
});

export default messaging