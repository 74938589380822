import {ref} from 'vue'

export const showNotification = ref<boolean>(false)
export const notifications = ref<any>([])
export const notification = ref<{title: string, message: string, isError: boolean}>({
    title:'',
    message:'',
    isError:false
})

const dbName = 'bardyDB';
const dbVersion = 1;

// const db: any = await new Promise((resolve, reject) => {
//     const request = indexedDB.open(dbName, dbVersion);

//     request.onupgradeneeded = (event: any) => {
//         const db = event.target.result;

//         // Verifique a versão e crie ou atualize a estrutura do banco de dados
//         if (event.oldVersion < 1) {
//         // Crie a object store 'notifications' durante o evento 'upgradeneeded'
//         if (!db.objectStoreNames.contains('notifications')) {
//             db.createObjectStore('notifications', { keyPath: 'id', autoIncrement: true });
//         }
//         }
//     };

//     request.onsuccess = (event: any) => resolve(event.target.result);
//     request.onerror = (event: any) => reject(event.target.error);
// });

const db = await new Promise<IDBDatabase>((resolve, reject) => {
    const request = indexedDB.open(dbName, dbVersion);

    request.onupgradeneeded = (event: any) => {
        const db = event.target.result;

        // Verifique a versão e crie ou atualize a estrutura do banco de dados
        if (event.oldVersion < 1) {
            // Crie a object store 'notifications' durante o evento 'upgradeneeded'
            if (!db.objectStoreNames.contains('notifications')) {
                db.createObjectStore('notifications', { keyPath: 'id', autoIncrement: true });
            }
        }
    };

    request.onsuccess = (event: any) => {
        const db = event.target.result;
        resolve(db);
    };

    request.onerror = (event: any) => reject(event.target.error);
});

//Start crud
export async function addNotifications(notificationData: any){
    const notificationsStore = db.transaction(['notifications'], 'readwrite').objectStore('notifications');

    for(const notification of notificationData){
        const requestAdd = notificationsStore.add(notification);

        requestAdd.onsuccess = function(event: any) {
            console.log('Notification added to IndexedDB');
        };
    
        requestAdd.onerror = function(event: any) {
            console.error('Error adding notification to IndexedDB:', event.target.error);
        };
    }
}

export async function updateNotification(recordId: any, updatedData: any) {
    try {
      const transaction = db.transaction(['notifications'], 'readwrite');
      const objectStore = transaction.objectStore('notifications');
  
      const getRequest = objectStore.get(recordId);
  
      getRequest.onsuccess = function (event: any) {
        const existingRecord = getRequest.result;
  
        if (existingRecord) {
          Object.assign(existingRecord, updatedData);
  
          /*const putRequest = */objectStore.put(existingRecord);
  
          // putRequest.onsuccess = function (event: any) {
          //   console.log('Registro atualizado com sucesso!');
          // };
  
          // putRequest.onerror = function (event: any) {
          //   console.error('Erro ao atualizar o registro:', event.target.error);
          // };
        } else {
          console.error('Registro não encontrado');
        }
      };
  
      transaction.oncomplete = function (event: any) {
        console.log('Transação concluída');
      };
  
      transaction.onerror = function (event: any) {
        console.error('Erro na transação:', event.target.error);
      };
    } catch (error) {
      console.error('Erro ao abrir o IndexedDB:', error);
    }
}

export async function deleteNotification(notificationId: number){
  const objectStore = db.transaction(['notifications'], 'readwrite').objectStore('notifications')
  objectStore.delete(notificationId)
}

export function clearNotifications(){
    const objectStore = db.transaction(['notifications'], 'readwrite').objectStore('notifications')
    objectStore.clear()
}
//End crud

const fetchNotifications = async () => {
    try {
        const transaction = db.transaction(['notifications'], 'readonly');
        const notificationsStore = transaction.objectStore('notifications');
        const requestGetAll = notificationsStore.getAll();

        requestGetAll.onsuccess = (event: any) => {
            notifications.value = event.target.result;
        };

        requestGetAll.onerror = (event: any) => {
            console.error('Error getting notifications from IndexedDB:', event.target.error);
        };
    } catch (error) {
        console.error('Error opening IndexedDB:', error);
    }
};

// Chame a função de recuperação quando o módulo for carregado
fetchNotifications();

export function appearNotification(title: string, message: string, isError = false){
    const audio = document.getElementById('notificationAudio') as HTMLAudioElement
    
    const playPromise = audio.play();

    if (playPromise !== undefined) {
        playPromise.then(_ => {
            // Autoplay started successfully
        }).catch(error => {
            // Autoplay was prevented, handle the error or provide alternative user interaction
            console.error('Autoplay was prevented:', error);
        });
    }

    showNotification.value = true
    notification.value = {
        title: title,
        message: message,
        isError: isError
    }
    setTimeout(() => {
        showNotification.value = false
    }, 3000)
}

export const readAllNotifications = () => {
    notifications.value = notifications.value.map((n: any) => {
        if(!n.is_read){
            updateNotification(n.id, { is_read: true})
        }
        return {...n, is_read: true}
    })
}

document.addEventListener('visibilitychange', function() {
    if (document.visibilityState === 'visible') {
        fetchNotifications();
    } 
});