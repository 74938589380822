
import axiosClient from "@/api/axios"
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { business, restaurant, addOns, cart, deliveryType, apiBaseUrl, tableNumber, isLoading, hideNav } from '@/global/state/GlobalState'
import { isQrcodeReaderOpen, isNotificationsModalOpen } from '@/global/state/ModalState'
import { customer, isAuthModalOpen } from "@/global/state/AuthState";
import MyModal from "@/components/modal/MyModal.vue";
import CartSidebar from './components/CartSidebar.vue'
import FooterComponent from './components/FooterComponent.vue'
import AuthModal from "./components/AuthModal.vue";
import NotificationsModal from "./components/NotificationsModal.vue";
import { QrcodeStream } from 'vue-qrcode-reader'
import { useToast } from "vue-toastification";
import '../../../services/firebase'
import CustomerRequestModal from "./components/CustomerRequestModal.vue";
import { showNotification, notification, notifications, clearNotifications } from '../../../global/state/NotificationState'
import CentralLoader from '@/components/loaders/CentralLoader.vue'
import { onInputSearch, searchRegex } from "@/global/state/SearchState";
import { useRoute } from "vue-router";

const __default__ = {
    name:'web-layout', 
    components: {
        MyModal,
        CartSidebar,
        AuthModal,
        QrcodeStream,
        NotificationsModal,
        CustomerRequestModal,
        CentralLoader,
        FooterComponent
    },
    setup(){
        const isMenuOpen = ref<boolean>(false)
        const isCartOpen = ref<boolean>(false)
        const isInfoModalOpen = ref<boolean>(false)
        const currentInfoSection = ref<'about' | 'operating_hours' | 'social_media'>('about')
        const isCustomerRequestModal = ref<boolean>(false)
        const isInstallPwaModalOpen = ref<boolean>(false)
        const isMenuDropdownOpen = ref<boolean>(false)
        const designCustom = ref<any>({
            logo:'',
            primary_color:''
        })
        const searchQuery = ref<string>('')
        const isScrolled = ref<boolean>(false)
        const toast = useToast()
        const days = {
            1: 'Segunda-feira',
            2: 'Terça-feira',
            3: 'Quarta-feira',
            4: 'Quinta-feira',
            5: 'Sexta-feira',
            6: 'Sábado',
            7: 'Domingo'
        };
        const daysFormat: any = {
            0: 'Domingo',
            1: 'Segunda-feira',
            2: 'Terça-feira',
            3: 'Quarta-feira',
            4: 'Quinta-feira',
            5: 'Sexta-feira',
            6: 'Sábado'
        };
        const getDay = new Date().getDay()
        const today: string = daysFormat[getDay]
        const route = useRoute()

        function onDecode(code: any){
            let arrayCode = code[0].rawValue.split('?')
            let parameter = arrayCode[1].split('=')
            let commandNumber = parameter[1]

            if(parameter[0] == 'command' && commandNumber){
                localStorage.setItem('table_number', commandNumber)
                tableNumber.value = commandNumber
                toast.success('Comanda registrada para a mesa ' + commandNumber, { timeout: 2000 })
                isQrcodeReaderOpen.value = false
            } else{
                toast.error('Qrcode inválido!', { timeout: 1500 })
            }
        }
        let searchOffsetTop = 1
        function handleScroll() {
            isScrolled.value = window.scrollY > 0;
            const searchElement = document.querySelector('.web-layout__search') as HTMLElement
            
            if(searchElement){
                if(searchElement.offsetTop > 0){
                    searchOffsetTop = searchElement.offsetTop
                }
                if(window.scrollY >= searchOffsetTop){
                    searchElement?.classList.add('fixed')
                } else{
                    searchElement?.classList.remove('fixed')
                }
            }
        }

        function selectDeliveryType(){
            if(deliveryType.value){
                localStorage.setItem('deliveryType', deliveryType.value)
            }

            isMenuOpen.value = false
        }

        function clearSearchProduct(){
            searchQuery.value = ''
            searchRegex.value = new RegExp(`.*.*`, 'i')
            let searchInput = document.querySelector('#search-products')! as HTMLElement
            searchInput.focus()
        }

        function logout(){
            clearNotifications()
            localStorage.removeItem('customer'); 
            localStorage.removeItem('table_number'); 
            notifications.value = []
            customer.value = {}; 
            toast.success('Deslogado com sucesso.', { timeout:2000 })
            isMenuOpen.value = false
        }

        const unreadNotificationsLength = computed(() => {
            return notifications.value.filter((n: any) => !n.is_read).length
        })

        let deferredPrompt = ref<any>();

        function showInstallAlert() {
            const lastPromptDate: string | null = localStorage.getItem('pwaPromptLastShown');
            const now = new Date();

            if (!lastPromptDate || (now.getTime() - new Date(lastPromptDate).getTime()) > 15 * 24 * 60 * 60 * 1000) {
                isInstallPwaModalOpen.value = true;
                localStorage.setItem('pwaPromptLastShown', now.toISOString());
            }
        }

        function installPWA() {
            if (deferredPrompt.value) {
                deferredPrompt.value.prompt();
                deferredPrompt.value.userChoice.then(() => {
                    deferredPrompt.value = null;
                    isInstallPwaModalOpen.value = false;
                });
            }
        }
        const documentOnClick = (e: any) => {
            const menuDropdown = document.getElementById('menu_dropdown') as HTMLElement;
            if(e.target?.id != 'menu_dropdown' && !menuDropdown?.contains(e.target) && isMenuDropdownOpen.value){
                isMenuDropdownOpen.value = false
            }
        }

        onMounted(() => {
            if(!tableNumber.value){
                if(route.query.command){
                    tableNumber.value = parseInt(route.query.command as string)
                    localStorage.setItem('table_number', route.query.command as string)
                }
            }
            document.addEventListener('click', documentOnClick)
            axiosClient.get('/v1/config')
            .then((res) => {
                business.value = res.data.business;
                restaurant.value = res.data.restaurant;
                designCustom.value = res.data.restaurant.restaurant_design_custom;
                addOns.value = res.data.add_ons

                const link = document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'icon';
                link.href = restaurant.value.fav_icon ? `${apiBaseUrl}/storage/${restaurant.value.fav_icon}`:'/assets/favicon.png';

                const themeMeta = document.createElement('meta');
                themeMeta.name = 'theme-color'
                themeMeta.content =  res.data.restaurant.restaurant_design_custom.primary_color;
                document.head.appendChild(themeMeta);

                if (restaurant.value.meta_description) {
                    const metaDescription = document.createElement('meta');
                    metaDescription.name = 'description';
                    metaDescription.content = restaurant.value.meta_description;

                    document.head.appendChild(metaDescription);
                }

                if (restaurant.value.meta_image) {
                    const metaImage = document.createElement('meta');
                    metaImage.name = 'og:image';
                    metaImage.content = `${apiBaseUrl}/storage/restaurant/${restaurant.value.meta_image}`;

                    document.head.appendChild(metaImage);
                }
                document.querySelector('title')?.remove()
                const title = document.createElement('title');
                title.innerHTML = restaurant.value.name

                document.head.appendChild(link);
                document.head.appendChild(title);
                window.addEventListener('beforeinstallprompt', (e) => {
                    e.preventDefault();
                    deferredPrompt.value = e;
                    showInstallAlert();
                });
            }).catch((error) => {
                console.log(error)
            });
            
            if(customer.value.cpf){
                axiosClient.get(`/v1/customer/cpf/${customer.value.cpf}`)
                .then((res:any) => {
                    localStorage.setItem('customer', JSON.stringify({...customer.value, is_registered: true}))
                    customer.value = res.data.customer
                    customer.value.is_registered = true;
                }).catch(() => {
                    localStorage.setItem('customer', JSON.stringify({...customer.value, is_registered: false}))
                    customer.value.is_registered = false;
                })
            }

            window.addEventListener('scroll', handleScroll);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('scroll', handleScroll);
            document.removeEventListener('click',documentOnClick);
        });

        return {
            isCartOpen,
            business,
            designCustom,
            restaurant,
            isScrolled,
            isMenuOpen,
            deliveryType,
            selectDeliveryType,
            searchQuery,
            cart,
            isQrcodeReaderOpen,
            onDecode,
            apiBaseUrl,
            isInfoModalOpen,
            currentInfoSection,
            isNotificationsModalOpen,
            days,
            today,
            isCustomerRequestModal,
            customer,
            logout,
            isAuthModalOpen,
            tableNumber,
            showNotification,
            notification,
            notifications,
            unreadNotificationsLength,
            installPWA,
            isInstallPwaModalOpen,
            deferredPrompt,
            isMenuDropdownOpen,
            clearSearchProduct,
            isLoading,
            onInputSearch, 
            hideNav
        }
    },
}

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "887b26fa": (_ctx.designCustom?.primary_color),
  "555aff35": (_ctx.designCustom?.secondary_color),
  "29990b37": (_ctx.designCustom.menu_text_color),
  "60f72b74": (_ctx.designCustom.menu_text_hover_color),
  "d82fb144": (_ctx.designCustom.secondary_color),
  "76c4ea28": (_ctx.designCustom.primary_color)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__