<template>
  <div class="pb-8 lg:py-6 px-4 md:px-0 sm:pt-10" style="min-height: 40rem">
    <h5 class="font-inter" v-if="search"><strong>{{ totalProducts }} resultado(s)</strong> para <span class="text-secondary font-semibold">"{{ search }}"</span></h5>

    <div class="flex flex-col" :class="search ? 'pt-8':''">
      <div class="sm:pb-14 sm:mb-14" v-if="categories?.length && categories?.some((category: any) => category.products.length && category.products.some((p: any) => searchRegex.test(p.name)))">
        <div  v-for="category in categories" :key="category.id">
          <div class="pb-10" v-if="category.products.length && business?.base_urls && category.products.some((p: any) => searchRegex.test(p.name))">
            <h5 class="text-2xl text-zinc-800 font-bold pb-6">{{ category.name }}</h5>
            <div class="home__categories__item__products grid grid-cols-12">
              <div v-for="product in category.products" :key="product.id" class="col-span-6 md:col-span-4 lg:col-span-3 font-inter cursor-pointer" @click="setModalProduct(product)" :class="searchRegex.test(product.name) ? '' : 'hidden'">
                <img class="w-full rounded-xl" :src="product.small_image?.path ? (apiBaseUrl + '/storage/' + product.small_image?.path) : '/assets/no-image.png'" alt="" @error="($event.target as HTMLImageElement).src = '/assets/no-image.png'">
                <h5 class="font-bold py-2">{{ product.name }}</h5>
                <p v-if="!product.discount" class="font-bold">{{ product.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
                <p v-if="product.discount" class="font-bold"><span class="text-greenbase">De: </span><span class="line-through">{{ product.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span></p>
                <div v-if="product.discount" class="font-bold flex items-center gap-2">
                  <!-- <div class="rounded-xl p-1 text-xs bg-greenbase text-white">
                    -80%
                  </div> -->
                  <p>
                    <span class="text-greenbase">Por: </span> {{ (product.price - product.discount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex flex-col justify-center items-center sm:pt-14 m-auto" style="padding-bottom:13.2rem">
        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="252" height="166" viewBox="0 0 252 166" fill="none">
          <path d="M218.84 48.2183V58.5663C238.937 45.3962 235.303 43.5147 250.611 62.543C252.92 65.4507 252.193 69.5984 249.071 71.4798L218.84 90.0377V145.54C218.84 156.401 209.689 165.082 198.828 165.082H53.1446C42.2835 165.082 33.1328 156.401 33.1328 145.54V90.0377L2.90145 71.4798C-0.220032 69.5984 -0.904194 65.3651 1.40485 62.543C16.713 43.9851 12.0949 44.7975 33.1328 58.5663C33.1328 50.5701 31.7218 44.541 37.3661 42.4885C57.164 34.193 77.2185 28.8053 97.5295 26.2824C91.3293 6.1424 124.853 -6.98494 145.592 6.27069C152.989 10.9743 157.009 18.6711 154.358 26.6245C174.284 29.3184 194.381 34.5779 214.607 42.4885C217.044 43.2154 218.84 45.5245 218.84 48.2183ZM110.315 25.0851L141.444 25.2989C149.611 16.7469 125.965 7.93833 112.838 16.3193C108.904 18.7994 107.493 21.9209 110.315 25.0851ZM55.1971 46.5935H194.595C148.842 36.2027 100.95 35.7324 55.1971 46.5935Z" fill="#E0E0E0"/>
        </svg> -->
        <svg width="202" height="116" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none"><path d="M0.0247006 7.87823C0.162935 9.46532 0.887052 10.9532 2.06688 12.0735C3.24671 13.1938 4.80475 13.8732 6.46033 13.9891C8.11592 14.1051 9.76012 13.6501 11.0972 12.7061L14.2375 15.7092C14.4312 15.8952 14.6938 15.9996 14.9677 15.9996C15.2415 15.9996 15.5043 15.8953 15.6978 15.7093C15.8915 15.5235 16.0002 15.2714 16 15.0085C16.0002 14.7458 15.8915 14.4937 15.698 14.3078L12.5681 11.2947C13.9283 9.5308 14.2998 7.23853 13.5616 5.1613C12.8236 3.08439 11.0702 1.48725 8.86994 0.888013C6.66989 0.288729 4.30337 0.763496 2.53789 2.15874C0.772543 3.55382 -0.16685 5.69131 0.0244658 7.87791L0.0247006 7.87823ZM6.96867 2.62946C8.26895 2.62946 9.51591 3.12511 10.4353 4.00726C11.3547 4.88941 11.8713 6.08607 11.8713 7.33363C11.8713 8.58119 11.3547 9.77768 10.4353 10.6598C9.51591 11.542 8.2689 12.0376 6.96867 12.0376C5.66844 12.0376 4.42124 11.542 3.50184 10.6598C2.58245 9.77768 2.06587 8.58119 2.06587 7.33363C2.06587 6.08607 2.58245 4.88941 3.50184 4.00726C4.42124 3.12511 5.66844 2.62946 6.96867 2.62946Z" fill="#676767"></path></svg>
        <h5 class="font-medium text-lg pt-2">Nenhum resultado foi encontrado</h5>
        <p class="text-gray-500 text-center">Verifique a busca e tente novamente</p>
      </div>
    </div>
  </div>

  <ProductModal :isOpen="isProductModalOpen" :setSelectedProductVariations="(product: string) => selectedProduct.variations = product" :toggleModal="(value: boolean) => isProductModalOpen = value" :selectedProduct="selectedProduct" :setSelectedProduct="(product: any) => selectedProduct = product" />
</template>

<script lang="ts" setup>
import { computed, defineProps, onMounted, ref} from 'vue'
import { apiBaseUrl, business, categories, isLoading } from '@/global/state/GlobalState'
import { ProductModel } from '@/models/ProductModel'
import ProductModal from '@/components/modal/ProductModal.vue'
import { searchRegex } from '@/global/state/SearchState';
import axiosClient from '@/api/axios';

const props = defineProps({search: {type: String, required: false}})

const isProductModalOpen = ref<boolean>(false)
const selectedProduct = ref<any>({})

const totalProducts = computed(() => categories.value?.reduce((acc: number, category: any) => category.products?.filter((pr: ProductModel) => searchRegex.value.test(pr.name)).length + acc, 0))

onMounted(() => {
    searchRegex.value = new RegExp(`.*${props.search}.*`, 'i')
    if(!categories.value){
      isLoading.value = true
      axiosClient.get('/v1/categories')
      .then((res) => {
          isLoading.value = false
          categories.value = res.data
      })
    }
  })
const setModalProduct = (product: ProductModel) => {
  selectedProduct.value = product
  isProductModalOpen.value = !isProductModalOpen.value
}
</script>