import { OrderModel } from "@/models/OrderModel";
import { ref } from "vue";

export const business = ref<BusinessModel>()
export const restaurant = ref<any>({})
export const addOns = ref<any>({})
export const categories = ref<any>()
export const cart = ref<any>(JSON.parse(localStorage.getItem('cart') as string) ? JSON.parse(localStorage.getItem('cart') as string) : [])
export const deliveryType = ref<string | null>(localStorage.getItem('deliveryType') ?? 'presencial')
export const orders = ref<OrderModel[]>([])
export const cmFirebaseToken = ref<string | null>(localStorage.getItem("cmFirebaseToken") ?? null)
export const tableNumber = ref<number | null>(JSON.parse(localStorage.getItem("table_number") as string) ?? null)
export const isLoading = ref<boolean>(false)
export const hideNav = ref<boolean>(false)
export const apiBaseUrl = process.env.VUE_APP_API_BASE_URL