<template>
  <div>
    <MyModal :width="'50rem'" :height="'38rem'" :isOpen="isOpen" :toggleModal="toggleModal" :zIndex="200" :id="'product-modal'">
        <div v-if="selectedProduct.name && business?.base_urls" class="font-inter relative product-modal overflow-auto pb-4 md:pb-0" @scroll="handleModalScroll($event)">
          <div class="px-4 sticky flex items-center" style="z-index:50" id="product-title-nav">
            <button class="transition duration-200 hover:text-primary p-2 rounded-full box-shadow bg-white justify-self-start left-4" @click="toggleModal(!isOpen);setSelectedProduct({})">
              <svg viewBox="0 0 24 24" role="presentation" style="width: 1.5rem; height: 1.5rem;"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" style="fill: currentcolor;"></path></svg>
            </button>
            <h2 class="opacity-0 font-semibold left-1/2 -translate-x-1/2 absolute only-1-lines min-w-64 text-center">{{ selectedProduct.name }}</h2>
          </div>
          <div>
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12 md:col-span-5 w-full pl-8 overflow-auto max-h-full main-image-container relative">
                <img class="rounded-xl h-60 w-full object-contain main-product-image" :src="apiBaseUrl + '/storage/' + selectedProduct.large_image?.path" alt="Imagem do produto" srcset="" @error="($event.target as HTMLImageElement).src = '/assets/no-image.png'">
                <button class="absolute top-4 right-4 box-shadow p-2 rounded-lg" style="background:rgba(255, 255, 255, 0.8);z-index:201" @click="isAmplifyImageModalOpen = true">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                    <path d="M21 9V3H15" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="stroke-primary" />
                    <path d="M3 15V21H9" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="stroke-primary" />
                    <path d="M21 3L13.5 10.5" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="stroke-primary" />
                    <path d="M10.5 13.5L3 21" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="stroke-primary" />
                  </svg>
                </button>
              </div>
              <div class="col-span-12 md:col-span-7 flex flex-col product-modal__right">
                <div class="px-4 pb-4">
                  <div class="flex items-center gap-2 mb-2">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                      <path d="M20.75 13.25C20.75 18.08 16.83 22 12 22C7.17 22 3.25 18.08 3.25 13.25C3.25 8.42 7.17 4.5 12 4.5C16.83 4.5 20.75 8.42 20.75 13.25Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 8V13" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M9 2H15" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg> -->
                    <!-- <p class="text-xs text-gray-500">Tempo de preparo <span class="text-primary font-bold">10 minutos</span></p> -->
                  </div>
                  <h5 class="text-xl font-bold" id="product-modal__product-name">{{ selectedProduct.name }}</h5>
                  <p class="text-gray-500 max-w-full mb-4 mt-2 text-sm">{{ displayDescription(selectedProduct.description as string) }} <button v-if="!knowMore && (selectedProduct.description?.length ?? 0) > 184" @click="knowMore = true" class="text-primary font-semibold uppercase underline">saiba mais</button></p>
                  <div v-if="selectedProduct.discount" class="flex items-center gap-2">
                    <p class="font-bold text-sm"><span>De: </span><span class="line-through text-red-400">{{ selectedProduct.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span></p>
                    <p v-if="selectedProduct.discount_type == 'percent'" style="padding:.065rem .45rem;font-size:.56rem" class="bg-292 rounded-md text-white font-bold">-{{ selectedProduct.discount }}%</p>
                  </div>
                  <div v-if="selectedProduct.discount" class="font-bold flex items-center gap-2">
                    <p>
                      <span class="text-md">Por: </span> <span class="text-green-400 text-success">{{ (selectedProduct.price - (selectedProduct.discount_type == 'amount' ? selectedProduct.discount : (selectedProduct.price/100 * selectedProduct.discount))).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                    </p>
                  </div>  
                  <p v-if="!selectedProduct.discount" class="font-bold text-neutral-700">{{ selectedProduct.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
                </div>
                <div class="product-modal__right__variation" v-for="(variation, key) in JSON.parse(selectedProduct.variations)" :key="key">
                  <div class="p-4 sticky top-0 z-20 product-modal__right__variation__header" style="background:#F9F9F9;">
                    <div class="flex justify-between items-center">
                      <h6 class="font-semibold">{{ variation.name }}</h6> 
                      <div class="relative" v-if="variation.required == 'on' && variation.values.filter((option: any) => option.selected).length >= (variation.min ? variation.min : 1)">
                          <div class="h-4 w-4 bg rounded-full relative z-10 bg-success">
                              <svg viewBox="0 0 24 24" role="presentation" style="width: 1rem; height: 1rem;z-index:10"><path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" style="fill: #fff;"></path></svg>
                          </div>
                          <div class="absolute top-1/2 -translate-y-1/2 bg-success" style="width:100%;height:4px"></div>
                      </div>
                      <span v-else-if="variation.required == 'on'" class="text-white font-bold bg-black p-2 py-1 rounded-lg text-xs">obrigatório</span>
                    </div>
                    <div class="flex items-center gap-2 pt-1">
                      <svg xmlns="http://www.w3.org/2000/svg" style="min-width:16px" width="16" height="16" viewBox="0 0 24 24" fill="none">
                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 8V12" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 16H12.01" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      <p v-if="variation.min > 1 || variation.max" class="text-xs text-gray-500"><!--{{ variation.required == 'on' ? 'Obrigatório' : 'Opcional' }}, --><span v-if="variation.min > 1">mínimo {{ variation.min }}</span><span v-if="variation.min > 1 && variation.max"> e </span><span v-if="variation.max">máximo {{ variation.max }}</span> iten(s)</p>
                      <p v-else-if="variation.required == 'on'" class="text-xs text-gray-500">Selecione pelo menos uma opção</p>
                      <p v-else class="text-xs text-gray-500">Iten(s) opcionais</p>
                    </div>
                  </div>
                  <div class="grid grid-cols-12 gap-4 p-4">
                    <div 
                      @click="selectVariationOption(option, key, index)" 
                      style="height:6.5rem;background-position:center;background-size: cover;" :style="{backgroundImage:option.image ? `url('${apiBaseUrl}/storage/${option.image}')`:'unset'}" v-for="(option, index) in variation.values" 
                      :key="index" 
                      class="relative cursor-pointer px-2 py-2 rounded-2xl font-bold transition-colors duration-200 w-full col-span-4 flex justify-center items-center flex-col gap-2" 
                      :class="option.selected ? 'border-2 border-green-500':`${option.image ? 'border-white border-2':'border lg:hover:border-2'} lg:hover:border-neutral-700`"
                    >
                      <div v-if="option.image" class="absolute top-0 w-full h-full px-2 py-2 rounded-2xl left-0" style="background:rgb(0,0,0,0.1)"></div>
                      <!-- <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmfXgNz0gAX3QLYcX59gcfjhabDq1GvBZh0ruRkuHb7g&s" style="width:4rem;height:4rem"> -->
                      <span :class="option.image ? 'text-white relative text-sm':''">{{ option.label }}</span>
                      <div v-if="parseFloat(option.optionPrice) > 0" style="width:4.7rem" class="px-2 text-sm h-5 bg-red-500 rounded-xl text-white text-xs flex justify-center items-center">+{{ (parseFloat(option.optionPrice) /*+ selectedProduct.price - selectedProduct.discount*/).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'}) }}</div>
                    </div>
                  </div>
                </div>

                <div class="product-modal__addon-groups__addon-group" v-for="(addon_group, addon_group_index) in JSON.parse(selectedProduct.addon_groups ?? '[]')" :key="addon_group_index">
                  <div class="p-4 sticky top-0 product-modal__addon-groups__addon-group__header" style="background:#F9F9F9">
                    <div class="flex items-center justify-between gap-2">
                      <h6 class="font-semibold">
                        {{ addon_group.name }}
                      </h6>
                      <span v-if="addon_group.required == 'on' && (addOnsQuantity[addon_group_index]?.totalQuantity ?? 0) < (addon_group.min ?? 1)" class="text-white font-bold bg-black p-2 py-1 rounded-lg text-xs">obrigatório</span>
                      <div class="relative" v-else-if="addon_group.required == 'on'">
                          <div class="h-4 w-4 bg rounded-full relative z-10 bg-success">
                              <svg viewBox="0 0 24 24" role="presentation" style="width: 1rem; height: 1rem;z-index:10"><path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" style="fill: #fff;"></path></svg>
                          </div>
                          <div class="absolute top-1/2 -translate-y-1/2 bg-success" style="width:100%;height:4px"></div>
                      </div>
                    </div>
                    <div class="flex items-center gap-2 pt-1">
                      <svg xmlns="http://www.w3.org/2000/svg" style="min-width:16px" width="16" height="16" viewBox="0 0 24 24" fill="none">
                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 8V12" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 16H12.01" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      <div class="flex items-center gap-2 justify-between w-full">
                        <p class="text-xs text-gray-500" v-if="addon_group.min > 1 || addon_group.max"><span v-if="addon_group.min > 1">mínimo {{ addon_group.min }}</span><span v-if="addon_group.min > 1 && addon_group.max"> e </span><span v-if="addon_group.max">máximo {{ addon_group.max }}</span> iten(s)</p>
                        <p v-else-if="addon_group.required == 'on'" class="text-xs text-gray-500">Selecione pelo menos um item</p>
                        <p v-else class="text-xs text-gray-500">Iten(s) opcionais</p>
                        <span class="text-xs font-bold" v-if="addOnsQuantity[addon_group_index]?.totalQuantity && addon_group.max"><span class="text-success">{{ addOnsQuantity[addon_group_index]?.totalQuantity }}</span>/{{ addon_group.max }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <div v-for="(id, index) in addon_group.addon_ids" :key="index" class="w-full flex items-center justify-between p-4 mt-1 pb-5" :class="addon_group.addon_ids.length != (index + 1) ? 'border-b':''">
                      <div style="height:3rem" class="flex items-center gap-2">
                        <img class="rounded-lg" style="height:4rem;width:4rem" v-if="addOns[id].image" :src="addOns[id].image ? (apiBaseUrl + '/storage/' + addOns[id].image) : '/assets/no-image.png'" alt="Imagem do complemento" @error="($event.target as HTMLImageElement).src = '/assets/no-image.png'">
                        <div>
                          <h6 class="lg:text-md text-sm">{{ addOns[id].name }}</h6>
                          <span v-if="addOns[id].price" class="text-white bg-green-500 text-xs rounded-xl p-1 font-semibold">+{{ addOns[id].price.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</span>
                        </div>
                      </div>
                      <div class="flex gap-4 items-center text-sm py-2 rounded-lg pr-2" :class="addOnsQuantity[addon_group_index]?.addons[id]?.quantity ? 'border px-2':''" style="transition:padding .2s">
                        <button v-if="addOnsQuantity[addon_group_index]?.addons[id]?.quantity" 
                          class="transition duration-200 hover:text-green-500" 
                          @click="addOnsQuantity[addon_group_index]?.addons[id].quantity <= 0 ? '': handleAddonsQuantity(addon_group_index, id, -1)">
                            <svg viewBox="0 0 24 24" role="presentation" style="width: 1.2rem; height: 1.2rem;"><path d="M19,13H5V11H19V13Z" style="fill: currentcolor;"></path></svg>
                        </button>
                        {{ addOnsQuantity[addon_group_index]?.addons[id]?.quantity ? addOnsQuantity[addon_group_index]?.addons[id]?.quantity:'' }}
                        <button class="transition duration-200 hover:text-green-500" @click="addOnsQuantity[addon_group_index]?.totalQuantity < addon_group.max || !addon_group.max ? handleAddonsQuantity(addon_group_index, id, +1) : ''">
                            <svg viewBox="0 0 24 24" role="presentation" style="width: 1.2rem; height: 1.2rem;"><path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" style="fill: currentcolor;"></path></svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <ProductInformation/> -->
              </div>
            </div>

            
          </div>
          <div v-if="!restaurant.restaurant_config?.catalog_digital" class="flex items-center gap-4 pt-4 fixed bottom-0 box-shadow sm:shadow-none sm:mt-5 right-0 left-0 rounded-tl-2xl rounded-tr-2xl sm:rounded-2xl justify-between px-8 py-4 product-modal__add-to-cart bg-white">
            <div class="flex gap-4 items-center">
                <button class="transition duration-200 hover:text-green-500" @click="quantity <= 1 ? '':quantity--">
                    <svg viewBox="0 0 24 24" role="presentation" style="width: 1.5rem; height: 1.5rem;"><path d="M19,13H5V11H19V13Z" :style="{fill: quantity <= 1 ? '#ccc' : 'currentcolor'}"></path></svg>
                </button>
                <input type="text" class="w-3 text-center" :value="quantity" readonly/>
                <button class="transition duration-200 hover:text-green-500" @click="quantity++">
                    <svg viewBox="0 0 24 24" role="presentation" style="width: 1.5rem; height: 1.5rem;"><path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" style="fill: currentcolor;"></path></svg>
                </button>
            </div>
            <button :disabled="!canAddToCart || !areAddonGroupsRequirementsFulfilled" @click="addToCart" class="flex items-center justify-between p-3 md:p-4 rounded-2xl transition duration-200 font-inter" :style="{background: canAddToCart && areAddonGroupsRequirementsFulfilled ? restaurant.restaurant_design_custom.primary_color ? restaurant.restaurant_design_custom.primary_color : '#323232' : '#f5f5f5', color: canAddToCart && areAddonGroupsRequirementsFulfilled ? '#fff' : '#949494'}">
              <div>
                <p class="text-xs font-semibold">Adicionar ao carrinho</p>
                <p class="font-bold text-start font-bold">{{ totalPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'}) }}</p>
              </div>
              <svg viewBox="0 0 24 24" role="presentation" style="width: 1.8rem; height: 1.8rem;"><path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" :style="{fill: canAddToCart && areAddonGroupsRequirementsFulfilled ? '#fff' : '#949494'}"></path></svg>
            </button>
          </div>
        </div>
    </MyModal>

    <MyModal :width="'100%'" :height="'100%'" :className="''" :id="'amplify-image-modal'" :isOpen="isAmplifyImageModalOpen" :toggleModal="(v: any) => isAmplifyImageModalOpen = v" :zIndex="201">
      <div class="h-full" style="background:rgba(0,0,0,0.8)"> 
        <button class="transition duration-200 hover:text-primary p-2 rounded-full justify-self-start left-4 absolute top-3" @click="isAmplifyImageModalOpen = false;toggleModal(true)">
          <svg viewBox="0 0 24 24" role="presentation" style="width: 1.5rem; height: 1.5rem;"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" style="fill: #fff;"></path></svg>
        </button>
        <div class="flex justify-center items-center h-full flex flex-col gap-8">
          <img style="height:20rem" :src="apiBaseUrl + '/storage/' + selectedProduct.large_image?.path" alt="Imagem do produto" srcset="" @error="($event.target as HTMLImageElement).src = '/assets/no-image.png'">
          <div class="w-full flex flex-col md:items-center md:justify-center">
            <p class="text-white text-bold text-lg w-full text-start px-4">{{ selectedProduct.name }}</p>
            <p class="text-white text-semibold text-sm w-full text-start px-4 only-1-lines">{{selectedProduct.description}}</p>
          </div>
        </div>
      </div>
    </MyModal>
  </div>
</template>

<script lang="ts" setup>
import MyModal from './MyModal.vue';
import { defineProps, ref, watch, computed } from 'vue';
import { business, addOns, cart, restaurant, apiBaseUrl } from '@/global/state/GlobalState';
import {useToast} from "vue-toastification";
import { OptionModel, ProductModel, VariationModel } from '@/models/ProductModel';
// import ProductInformation from './components/ProductInformation.vue'

const props = defineProps<{selectedProduct: ProductModel, toggleModal: Function, isOpen: boolean, setSelectedProduct: Function, setSelectedProductVariations: Function}>()

const quantity = ref<number>(1)
const addOnsQuantity = ref<any>({})
const canAddToCart = ref<boolean>(true)
const knowMore = ref<boolean>(false)
const isAmplifyImageModalOpen = ref<boolean>(false)
const toast = useToast()

function displayDescription(description?: string){
  if(description && description?.length > 184){
    if(knowMore.value){
      return description
    }
    return description.substring(0, 164) + '...'
  }

  return description
}

function handleModalScroll(e: any){
  const productTitleNav = document.querySelector('#product-title-nav') as HTMLElement
  const modalProductTitle = document.querySelector('#product-modal__product-name') as HTMLElement
  if(productTitleNav && modalProductTitle){
      if(e.target.scrollTop >= modalProductTitle.offsetTop){
          productTitleNav.classList.add('show-title')
      } else{
          productTitleNav.classList.remove('show-title')
      }
  }
}

watch(() => props.selectedProduct, (newProduct: ProductModel) => {
    let values: any = {}
    knowMore.value = false
    
    if(newProduct.addon_groups){
      JSON.parse(newProduct.addon_groups).forEach((addon_group: any, index: number) => {
          addon_group.addon_ids.forEach((id: any) => {
            if(!values[index]){
              values[index] = {totalQuantity:0, name:addon_group.name, addons: {}}
            }
            values[index].addons[id] = { name: addOns.value[id].name, quantity: 0, price: addOns.value[id].price, id: id}
          })
      })

      props.setSelectedProductVariations(JSON.stringify(JSON.parse(props.selectedProduct.variations).map((variation: any) => {
        variation.values = variation.values.map((op: any) => {
          op.selected = false
          return op
        })

        return variation
      })))
  
      addOnsQuantity.value = values
      quantity.value = 1
    }
}, { deep: false})

watch(() => props.selectedProduct.variations, (newProductVariations, oldProductVariations) => {
    let required: boolean[] = []
    let min: boolean[] = []
    let max: boolean[] = []
    let errorMessage = ''

    if(newProductVariations){
      JSON.parse(newProductVariations as string).forEach((variation: any, index: number) => {
        let selectedOptions = variation.values.filter((option: any) => option.selected)
        let oldSelectedOptions = JSON.parse(JSON.parse(oldProductVariations ?? 'null')?.length === JSON.parse(newProductVariations).length ? oldProductVariations : newProductVariations)[index].values.filter((option: any) => option.selected)

        if(variation.required == 'on'){
          required.push(selectedOptions.length ? true : false)
        }

        if(selectedOptions.length){
          min.push(variation.min <= selectedOptions.length)
          if(variation.min > selectedOptions.length && oldSelectedOptions.length > selectedOptions.length){
            errorMessage = `Você precisa selecionar no mínimo ${variation.min} iten(s)`
          }
        }

        if(variation.max < selectedOptions.length && variation.max){
          props.setSelectedProductVariations(oldProductVariations)
          errorMessage = `Você pode selecionar no máximo ${variation.max} iten(s)`
        }
        max.push(variation.max >= selectedOptions.length || !variation.max)
      })

      canAddToCart.value = required.every((bool: boolean) => bool) && min.every((bool: boolean) => bool) && max.every((bool: boolean) => bool)

      if(!canAddToCart.value && errorMessage){
        toast.warning(errorMessage, {timeout: 2000})
      }
    }
})

watch(() => quantity.value, (newQuantity) => {
  canAddToCart.value = newQuantity > 0 && canAddToCart.value
})

const areAddonGroupsRequirementsFulfilled = computed<boolean>(() => {
  return Object.keys(addOnsQuantity.value).every((addonGroupIndex: any) => {
    if(JSON.parse(props.selectedProduct.addon_groups)){
      let addon_group = JSON.parse(props.selectedProduct.addon_groups)[addonGroupIndex]
      if(addon_group.required == 'on'){
        return addOnsQuantity.value[addonGroupIndex].totalQuantity >= (addon_group.min ? addon_group.min:1)
      } else{
        if(addon_group.min > 0 && addOnsQuantity.value[addonGroupIndex].totalQuantity > 0){
          return addOnsQuantity.value[addonGroupIndex].totalQuantity >= (addon_group.min ? addon_group.min:1)
        } else{
          true
        }
      }
    }
    return true
  })
})

const totalPrice = computed<number>(() => {
    let totalPrice: number = quantity.value * (props.selectedProduct.price - (props.selectedProduct.discount_type == 'amount' ? props.selectedProduct.discount : (props.selectedProduct.price/100 * props.selectedProduct.discount)))
    for(let addonGroupIndex in addOnsQuantity.value){
      for(let addonId in addOnsQuantity.value[addonGroupIndex].addons){
        totalPrice += (addOnsQuantity.value[addonGroupIndex].addons[addonId].quantity * quantity.value) * parseFloat(addOnsQuantity.value[addonGroupIndex].addons[addonId].price)
      }
    }

    JSON.parse(props.selectedProduct.variations).forEach((variation: any) => {
        variation.values.forEach((option: any) => {
            if(option.selected){
                totalPrice += parseFloat(option.optionPrice) * quantity.value;
            }
        })
    })

    return totalPrice
})

function handleAddonsQuantity(addonGroupIndex: any, id: any, value: any){
  addOnsQuantity.value[addonGroupIndex].addons[id].quantity += value
  addOnsQuantity.value[addonGroupIndex].totalQuantity += value
}

function selectVariationOption(option: any, variationIndex: number, optionIndex: number){
  let optionSelected = { ...option, selected: !option.selected };
  let variations = [...JSON.parse(props.selectedProduct.variations)];

  if(variations[variationIndex].type == 'single'){
    variations[variationIndex]['values'] = variations[variationIndex]['values'].map((option: any) => {
      return { ...option, selected: false }
    })
  }

  variations[variationIndex]['values'][optionIndex] = optionSelected;
  props.setSelectedProductVariations(JSON.stringify(variations));
}

function addToCart(): void{
    let alreadyInCart: any = false
    let isDifferentItem: boolean = true
    const addonsQuantityFormated = Object.keys(addOnsQuantity.value)
    .filter((addonGroupIndex) => {
      const addonGroup = addOnsQuantity.value[addonGroupIndex];
      for (const addonId in addonGroup.addons) {
        if (addonGroup.addons[addonId].quantity > 0) {
          return true;
        }
      }
      return false; // Se nenhum addon tiver quantidade maior que 0, retorna false
    }).map((addonGroupIndex) => {
      let addonGroup = addOnsQuantity.value[addonGroupIndex]
      addonGroup.addons = Object.keys(addonGroup.addons).filter((id: any) => addonGroup.addons[id].quantity).map((id: any) => addOnsQuantity.value[addonGroupIndex].addons[id])

      return addonGroup
    });

    if(canAddToCart.value && areAddonGroupsRequirementsFulfilled.value){
        let cartItems = JSON.parse(localStorage.getItem('cart') as string) ?? []
        let productVariations = JSON.parse(props.selectedProduct.variations).filter((v: VariationModel) => v.values.some((op: OptionModel) => op.selected ? true : false))

        if (Array.isArray(cartItems)) {
            cartItems.forEach((product, indexCartItem: number) => {
              if(product.id == props.selectedProduct.id && isDifferentItem && JSON.stringify(addonsQuantityFormated) == JSON.stringify(product.addon_groups)){
                  if(JSON.parse(product.variations).length && JSON.parse(product.variations).length == productVariations.length){
                    isDifferentItem = !JSON.parse(product.variations).every((variation: VariationModel, index: number) => {
                        let selectedProductVariation = productVariations[index]

                        if(selectedProductVariation){
                            const areSameVariationOptions = selectedProductVariation.values.every((option: any) => {
                                //verifica se a option do produto selecionado está selecionada na variação do produto do carrinho
                                let optionExistInCartProduct = variation.values.label.some((label: any) => {
                                    //option from selected poroduct variation
                                    if(option.label == label){
                                        return true;
                                    }

                                    return false
                                })

                                if(!option.selected){
                                  optionExistInCartProduct = !optionExistInCartProduct
                                }

                                return optionExistInCartProduct
                            })

                            if(areSameVariationOptions){
                              alreadyInCart = indexCartItem
                            }

                            return areSameVariationOptions
                        }
                    })
                  } else{
                    if(JSON.parse(product.variations).length == productVariations.length){
                      isDifferentItem = false
                      alreadyInCart = indexCartItem
                    } 
                  }
              } 
            })
        } 

        if(alreadyInCart !== false && !isDifferentItem){
          cartItems[alreadyInCart].quantity += quantity.value
        } else {
            let variations = JSON.parse(props.selectedProduct.variations).map((variation: any) => {
                let newVariation = { ...variation, values: {label: variation.values.map((option: any) => option.selected ? option.label : null).filter((i: string) => i != null), prices: variation.values.map((option: any) => option.selected ? option.optionPrice : null).filter((i: string) => i != null)}}
                if(newVariation.values.label.length){
                  return newVariation
                }
            })

            cartItems.push({
                ...props.selectedProduct,
                variations: JSON.stringify(variations.filter((v: VariationModel) => v ? true : false)),
                quantity: quantity.value, 
                addon_groups: addonsQuantityFormated,
                restaurant_id: restaurant.value.id
            })
        }

        cart.value = cartItems
        // localStorage.setItem('cart', JSON.stringify(cartItems))
        props.setSelectedProductVariations(JSON.stringify(JSON.parse(props.selectedProduct.variations).map((variation: any) => {
          variation.values = variation.values.map((op: any) => {
            op.selected = false
            return op
          })

          return variation
        })))
        props.setSelectedProduct({})
        quantity.value = 1
        toast.success('Item adicionado ao carrinho!', {timeout:1500})
        props.toggleModal(false)
    }
}

</script>

<style lang="scss">
  #amplify-image-modal{
    transition:opacity .2s !important;
    border-radius:0 !important;
    background:transparent;
    img{
      height:35rem !important;
    }
    p{
      max-width:50rem
    }
  }
  @media screen and (min-width:800px){
    .product-modal{
      .main-product-image{
        width:100%;
        object-fit: fill;
      }
      #product-title-nav{
        margin-top:.5rem;
        button{
          box-shadow:unset
        }
      }
      &__right{
        height:26rem
      }
    }
  }

  @media screen and (max-width:800px){
    #amplify-image-modal{
      img{
        height:20rem !important;
      }
    }
    #product-title-nav{
      top:0;
      padding:0.5rem 1rem;
      transition:all .2s;
      width:100%;
  
      &.show-title{
        background:white;
        
        button{
          box-shadow:unset
        }
  
        h2{
          opacity:1
        }
      }
    } 

    .product-modal{
      padding-bottom:8rem !important;
      max-height:100%;
      .main-product-image{
        height:18rem;
        margin:auto;
        margin-top:0rem;
        width:25rem
      }
      &__add-to-cart{
        z-index:120
      }
    }

    .product-modal__right__variation__header{
      top:3.5rem;
    }

    .product-modal__addon-groups__addon-group__header{
      top:3.5rem;
    }
  }

  @media screen and (min-width:800px){
    .product-modal{
      &__right{
        overflow: auto;
      }

      &__add-to-cart{
        position:static
      }
    }
  }

  @media screen and (max-width:500px) {
    .product-modal{
      padding-top:0;
      #product-title-nav{
        position:fixed
      }
      .main-image-container{
        padding-left: 0;
        padding-right: 0;
      }
      .main-product-image{
        height:18rem;
        margin:auto;
        margin-top:2rem;
        width:100% !important;
        object-fit:fill;
        margin:unset !important;
        border-radius: unset;
      }
    }
  }
</style>