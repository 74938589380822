import router from "@/router";
import axios from "axios";

const apiBaseURL = `${process.env.VUE_APP_API_BASE_URL}/api`;

const axiosClient = axios.create({
    baseURL: apiBaseURL,
});

axiosClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && (error.response.status === 404 || error.response.status === 403)) {
        // router.push({ name: 'nonexistent-domain' }); // Substitua '404' pelo nome da rota da sua página 404
      }
      return Promise.reject(error);
    }
);

export default axiosClient;